// Preview - Tools - Adjust Size - 800 x 800 - Save
import abbie from "assets/img/team/abbie.jpeg"
import addieNalder from "assets/img/team/addie.jpg"
import alissaHokanson from "assets/img/team/alissa.jpg"
import annaNorman from "assets/img/team/annaNorman.jpg"
import annElise from "assets/img/team/annElise.jpg"
import annistonD from "assets/img/team/anniston-d.jpg"
import aryYoung from "assets/img/team/aryYoung.jpg"
import ashlyeHokanson from "assets/img/team/ashlyeHokanson.jpg"
import autumnAkiu from "assets/img/team/autumnAkiu.jpg"
import avrey from "assets/img/team/avery.jpg"
import avery from "assets/img/team/avery.jpeg"
import bailey from "assets/img/team/bailey.jpg"
import baylee from "assets/img/team/baylee.jpeg"
import blake from "assets/img/team/blake.jpg"
// import blankAvatar from "assets/img/team/blank.jpg"
import bre from "assets/img/team/bre.jpg"
import brendon from "assets/img/team/brendon.jpg"
import briDelph from "assets/img/team/briDelph.jpg"
import chelseaONeal from "assets/img/team/chelseaONeal.jpg"
import gregs from "assets/img/team/gregs.jpg"
import hannah from "assets/img/team/hannah.jpg"
import izzy from "assets/img/team/izzy.jpg"
import jade from "assets/img/team/jade.jpg"
import joD from "assets/img/team/joD.jpg"
import kariIgew from "assets/img/team/kariIgew.jpg"
import katie from "assets/img/team/katie.jpg"
import kayleenOlson from "assets/img/team/kayleenOlson.jpg"
import kellyLewis from "assets/img/team/kellyLewis.jpg"
import kloiePellegrini from "assets/img/team/kloiePellegrini.jpg"
import lindsey from "assets/img/team/lindsey.jpeg"
import lindseyJ from "assets/img/team/lindsey-j.jpg"
import london from "assets/img/team/london.jpeg"
import lydia from "assets/img/team/lydia.jpg"
import mia from "assets/img/team/mia.jpg"
import neleKaufusi from "assets/img/team/nele-kaufusi.jpeg"
import regan from "assets/img/team/regan.jpg"
import samKirkby from "assets/img/team/sam-kirkby.jpeg"
import sarah from "assets/img/team/sarah.jpg"
import shelby from "assets/img/team/shelby.jpg"
import sierraSedivy from "assets/img/team/sierraSedivy.jpeg"
import summer from "assets/img/team/summer.jpeg"
import tallie from "assets/img/team/tallie.jpg"
import tasha from "assets/img/team/tasha.jpg"

export const TEAM_MEMBERS = [
  {
    img: joD,
    name: "JoD",
    title: "Owner / Founder",
    admin: true,
    primary: "0",
    secondary: "0",
    started: "01 November 1989",
    work: `As the owner of Utah Swim Academy, JoD (pronounced Jody) has been a swim instructor and coach for over ${(new Date().getFullYear() - 1983)} years. Teaching over thousands of students she finished her online swim instructor course for parents and teachers. In addition to being an American Red Cross Lifeguard, JoD is also a certified Water Safety Instruction Trainer.`,
    experience: "JoD has moved to St. George to start her retirement.",
    email: "",
    phone: "801.636.2708",
    isActive: true
  },
  {
    img: chelseaONeal,
    name: "Chelsea",
    title: "Instructor",
    primary: "4",
    secondary: "2",
    started: "05 December 2016",
    work: "Babies, toddlers, adults, students with disabilities, and students working on stroke work",
    language: "English",
    experience: `Chelsea has been teaching for ${(new Date().getFullYear() - 2006)} years (2006). She has been on the swim team since 10 years old and has coached the swim team for 13 years. She's CPR certified and Survival teaching certified.`,
    goals: "Work well with all types and loves to encourage her students while pushing them with their progression. She is firm in a patient way.",
    hobbies: "",
    email: "",
    phone: "801.636.2454",
    isActive: true
  },
  {
    img: alissaHokanson,
    name: "Alissa",
    title: "Manager",
    primary: "4",
    secondary: "3",
    started: "01 November 2019",
    work: "All ages, abilities, and advanced stroke work. Specializes in teaching kids with big personalities that don't necessarily like coming to lessons.",
    language: "English",
    experience: `Alissa swam competitively from ages 6 to 17. She has been a swim teacher off-and-on for most of her life.`,
    goals: "Alissa aims to help kids gain a love for swimming through teaching with love, patience, and hard work. She loves helping kids who struggle in the water!",
    hobbies: "Loves watching children get excited about swimming and having fun in the water.",
    email: "alissah@utahswimacademy.com",
    phone: "385.204.5156",
    isActive: true
  },
  {
    img: autumnAkiu,
    name: "Autumn",
    title: "Instructor",
    primary: "2",
    secondary: "4",
    started: "01 January 2020",
    work: "Babies, toddlers, students with disabilities and students working on stroke work",
    language: "English, Japanese",
    experience: "4 years of competitive swimming and 4 years of water polo during highschool. Her second year during competitive swimming, she helped younger teammates with techniques for competitions and swimming workouts.",
    goals: "To get anyone who takes lessons confident in themselves and their strengths.",
    hobbies: "Loves seeing the improvement in individuals and the excitement children show when they get something for the first time.",
    email: "autumna@utahswimacademy.com",
    phone: "385.985.3033",
    isActive: false
  },
  {
    img: kayleenOlson,
    name: "Kayleen",
    title: "Instructor",
    primary: "1",
    secondary: "3",
    started: "01 June 2020",
    work: "Babies, toddlers, students with disabilities and students working on stroke work",
    language: "English",
    experience: "Swam in high school, taught the swim team this past summer and was a certified lifeguard for 3 years.",
    goals: "To help any kid who comes to her be safe in the water, but still able to have fun!",
    hobbies: "She loves the kids, all of the different funny and sweet cute kids. They always brighten her day and makes her so happy being able to help them.",
    email: "kayleeno@utahswimacademy.com",
    phone: "801.995.9514",
    isActive: false
  },
  {
    img: ashlyeHokanson,
    name: "Ashlye",
    title: "Head Instructor",
    primary: "1",
    secondary: "3",
    started: "01 June 2020",
    work: "Babies & toddlers",
    language: "English",
    experience: `Took private swim lessons growing up.`,
    goals: "Getting kids to love the water and be able to trust me and themselves!",
    hobbies: "Loves watching kids learn to love the water and get over their fears, it’s so rewarding!",
    email: "ashlyeh@utahswimacademy.com",
    phone: "801.900.4802",
    isActive: false
  },
  {
    img: kellyLewis,
    name: "Kelly Lewis",
    title: "Instructor",
    primary: "1",
    secondary: "3",
    started: "01 January 2021",
    work: "Babies & toddlers",
    language: "English",
    experience: "Was on the swim team for 3 years and played water polo for 2 years.",
    goals: "Hoping to play competitive volley ball in High School and serve a L.D.S. Mission.",
    hobbies: "Basketball, volley ball, surfing, being in the water, and hanging out with friends.",
    email: "kellyl@utahswimacademy.com",
    phone: "385.204.6314",
    isActive: false
  },
  {
    img: kariIgew,
    name: "Kari Igwe",
    title: "Swim Coach",
    primary: "2",
    secondary: "3",
    started: "01 May 2021",
    work: "Swim team and adult students",
    language: "English",
    experience: "Has 5 years of swim team, 3 years of lifequard, and 7 years of teaching swim.",
    goals: "Her goal is to challenge her students while also making it fun! Her goal is to always see her students trying their hardest!",
    hobbies: "She loves teaching kids and helping them be safe! As a parent, she loves the relief that comes knowing her kids are safe in the water. She loves teaching adults and helping them to learn and refine a skill!",
    email: "kariigwe@utahswimacademy.com",
    phone: "801.717.2980",
    isActive: false
  },
  {
    img: regan,
    name: "Regan",
    title: "Instructor",
    primary: "2",
    secondary: "1",
    started: "01 September 2021",
    work: "Babies, toddlers, and students working on stroke work",
    language: "English",
    experience: "Was on the high school swim team and also in a club team. She swam competitively for about 8 years. She worked in Salt Lake City teaching swim lessons for 6 months.",
    goals: "Helping all of the kids to feel confident and safe.",
    hobbies: "Her favorite thing is finding out what works for each kid and then seeing them progress. She loves how happy it makes them when they start progressing.",
    email: "reganm@utahswimacademy.com",
    phone: "385.448.0935",
    isActive: false
  },
  {
    img: mia,
    name: "Mia",
    title: "Instructor",
    primary: "3",
    secondary: "3",
    started: "01 January 2022",
    work: "All ages and advance stroke work",
    language: "English, German",
    experience: "Mia has been swimming since she was 2 years old. She was on a competitive swim team for 6 years and has been teaching for 3 years.",
    goals: "Mia loves seeing kids' confidence increase and watching them enjoy the water",
    hobbies: "",
    email: "miacardon@utahswimacademy.com",
    phone: "385.497.5309",
    isActive: false
  },
  {
    img: briDelph,
    name: "Bri",
    title: "Instructor",
    primary: "3",
    secondary: "1",
    started: "01 February 2022",
    work: "All ages and abilities. Specializes in Teaching babies and more timid children.",
    language: "English",
    experience: "Bri has been teaching swim lessons for three years. She also participated in one year of swim team.",
    goals: "Bri enjoys connecting with each of her students and watching them grow with confidence. She aims to help her students overcome the fears that hold them back from achieving safe swimming.",
    hobbies: "Loves watching kids progress and seeing how far they come in their swimming. One of her favorite things about teaching is when her kids show how proud they are of the things they’ve achieved.",
    email: "bridelph@utahswimacademy.com",
    phone: "801.900.3580",
    isActive: true
  },
  {
    img: gregs,
    name: "Greg S.",
    title: "Instructor",
    primary: "2",
    secondary: "3",
    started: "01 May 2022",
    work: "Older kids working on stroke work",
    experience: "Swam on the BYU's swim team.",
    goals: "Great with stroke work.",
    hobbies: "Enjoys swimming, lifting, sports, cars, off-roading, and camping.",
    email: "gswimsen@gmail.com",
    phone: "512.814.6627",
    isActive: false
  },
  {
    img: lydia,
    name: "Lydia",
    title: "Instructor",
    primary: "1",
    secondary: "4",
    started: "01 June 2022",
    work: "Babies, toddlers, and more active children",
    language: "English",
    experience: "Lydia has been teaching swim lessons for 3 years and was a lifeguard for 4 years.",
    goals: "Lydia's goal is to not only have her students comfortable in the water, but love it! She wants her students to be excited to learn new skills in the pool and become well-rounded swimmers.",
    hobbies: "Getting to work with all different types of kids. She could be having the worst day but when she gets in the water to teach, her students always makes her feel better!",
    email: "lydiap@utahswimacademy.com",
    phone: "801.368.2070",
    isActive: true
  },
  {
    img: avrey,
    name: "Avery",
    title: "Instructor",
    primary: "2",
    secondary: "4",
    started: "01 June 2022",
    work: "Babies and small children. Avery specializes in safety skills",
    language: "English, ASL (Proficient)",
    experience: "Avery taught at Utah Swim Academy for 9 months in 2022 and returned in 2024!",
    goals: "Avery loves to watch as a kid learns to swim and recognize how to capable they are of learning new and challenging things.",
    hobbies: "Seeing their cute smiles when they jump into the pool!",
    email: "averyt@utahswimacademy.com",
    phone: "801.900.4016",
    isActive: true
  },
  {
    img: sierraSedivy,
    name: "Sierra",
    title: "Head Instructor",
    primary: "3",
    secondary: "4",
    started: "01 July 2022",
    work: "Babies, toddlers, students with disabilities and students working on stroke work",
    language: "English, Czech",
    experience: `Sierra has been swimming competitively since she was 6 years old up through high school, and taught the swim team.`,
    goals: "Wants her kids to feel safe and accomplished when they leave a lesson.",
    hobbies: "Having fun and laughing with kids, and seeing their progress.",
    email: "sierras@utahswimacademy.com",
    phone: "801.717.8145",
    isActive: false
  },
  {
    img: samKirkby,
    name: "Sam",
    title: "Instructor",
    primary: "1",
    secondary: "4",
    started: "01 July 2022",
    work: "Babies, toddlers and older kids working on stroke work",
    language: "English",
    experience: "Swim team from 8-12 years old and lifeguard for 2 years.",
    goals: "Some kind of progress no matter how small at every lesson.",
    hobbies: "The connection with the kids!!!",
    email: "samanthak@utahswimacademy.com",
    phone: "435.233.6930",
    isActive: false
  },
  {
    img: aryYoung,
    name: "Ary",
    title: "Instructor",
    primary: "3",
    secondary: "1",
    started: "01 January 2023",
    work: "Babies, toddlers, and students working on stroke work",
    language: "English",
    experience: "Swam on a competitive swim team (UVRays) for 7 years.",
    goals: "To help the kids learn how to swim and for them to have fun doing it.",
    hobbies: "Loves seeing the excitement each of the kids have as they progress.",
    email: "aryyoung@utahswimacademy.com",
    phone: "801.709.1085",
    isActive: false
  },
  {
    img: neleKaufusi,
    name: "Nele Kaufusi",
    title: "Instructor",
    primary: "1",
    secondary: "2",
    started: "01 January 2023",
    work: "Babies, toddlers, older kids",
    language: "English",
    experience: "Participated in club and high school swim team, taught club swim lessons in Salt Lake and in Texas for 3 years.",
    goals: "Her goals are for kids to be in a comfortable environment so they can learn swim safety, be confident in the water, and have a fun experience.",
    hobbies: "In teaching, she loves building one on one connections and teaching in a way that is fun and informative.",
    email: "nelekaufusi@utahswimacademy.com",
    phone: "801.477.6707",
    isActive: false
  },
  {
    img: kloiePellegrini,
    name: "Kloie",
    title: "Instructor",
    primary: "1",
    secondary: "4",
    started: "01 February 2023",
    work: "Toddlers, older kids, and students with disabilities",
    language: "English",
    experience: "Began swimming in elementary school and loved it ever since. She mainly swims recreationally, but she's excited to be working with kids and teaching them crucial survival mechanisms in the water.",
    goals: "To make sure that the students come and get the most out of their lessons, leaving feeling confident in their swimming and excited to return because of how much fun they had in the process.",
    hobbies: "She loves helping students accomplish something they didn’t think was possible for them to do before. The smile that beams on their faces and the peace and confidence they start to feel when swimming is such an incredible reward.",
    email: "kloiepellegrini@utahswimacademy.com",
    phone: "385.215.9051",
    isActive: false
  },
  {
    img: annaNorman,
    name: "Anna",
    title: "Instructor",
    primary: "1",
    secondary: "4",
    started: "01 February 2023",
    work: "Babies, toddlers, students with disabilities and students working on stroke work",
    language: "English, Spanish",
    experience: "Grew up with a pool in her backyard and spending weekends at a lake house. She can not remember not being able to swim. She taught swim lessons for 4 years in high school, as well as being part of the swim team.",
    goals: "To help children learn to be safe in the water, so they can enjoy swimming and feel comfortable and happy in the pool.",
    hobbies: "She loves working with each individual. Every person and every child is unique! She loves identifying children’s weaknesses and strengths so she can better adapt her teaching to meet their needs.",
    email: "annanorman@utahswimacademy.com",
    phone: "801.709.0896",
    isActive: false
  },
  {
    img: annistonD,
    name: "Anniston",
    title: "Instructor",
    primary: "0",
    secondary: "0",
    started: "01 July 2023",
    work: "Babies, toddlers, older kids, and students working on stroke work",
    language: "English",
    experience: "",
    goals: "",
    hobbies: "",
    email: "annistond@utahswimacademy.com",
    phone: "385.483.1022",
    isActive: false
  },
  {
    img: lindseyJ,
    name: "Lindsey",
    title: "Instructor",
    primary: "0",
    secondary: "0",
    started: "01 July 2023",
    work: "Babies, toddlers, older kids working on stroke work",
    language: "English",
    experience: "",
    goals: "",
    hobbies: "",
    email: "lindseyj@utahswimacademy.com",
    phone: "000.000.0000",
    isActive: false
  },
  {
    img: baylee,
    name: "Baylee",
    title: "Instructor",
    primary: "2",
    secondary: "2",
    started: "01 October 2023",
    work: "Babies and shy, timid kids who need more comfort",
    language: "English, Spanish",
    experience: "Baylee grew up on the swim team and has been teaching for over a year now.",
    goals: "Baylee loves seeing kids grow to love the water and feel comfortable and safe!",
    hobbies: "",
    email: "support@utahswimacademy.com",
    phone: "480.466.6494",
    isActive: false
  },
  {
    img: blake,
    name: "Blake",
    title: "Instructor",
    primary: "1",
    secondary: "4",
    started: "01 October 2023",
    work: "Toddlers, and students working on stroke work",
    language: "English, Spanish",
    experience: "",
    goals: "",
    hobbies: "",
    email: "support@utahswimacademy.com",
    phone: "801.900.6140",
    isActive: false
  },
  {
    img: addieNalder,
    name: "Addie",
    title: "Instructor",
    primary: "3",
    secondary: "1",
    started: "01 September 2020",
    work: "All ages, abilities and advanced stroke work",
    language: "English",
    experience: "Addie has been on swim teams her whole life and has been teaching group lessons for 12 years and survival lessons for over 2 years.",
    goals: "Addie loves watching her student's confidence grow. After learning safety, she loves how quickly they learn to love, and find their independence in the water.",
    hobbies: "",
    email: "addien@utahswimacademy.com",
    phone: "208.971.9630",
    isActive: true
  },
  {
    img: summer,
    name: "Summer",
    title: "Instructor",
    primary: "1",
    secondary: "2",
    started: "01 October 2023",
    work: "Babies, toddlers, and students working on stroke work",
    language: "English",
    experience: "",
    goals: "",
    hobbies: "",
    email: "support@utahswimacademy.com",
    phone: "775.250.7008",
    isActive: false
  },
  {
    img: brendon,
    name: "Brendon",
    title: "Instructor",
    primary: "1",
    secondary: "4",
    started: "01 February 2024",
    work: "All ages, abilities, and advanced stroke work",
    language: "English, Spanish, Portuguese",
    experience: "Brendon has been teaching for four years and loves making connections with children and their families.",
    goals: "Brendon enjoys helping kids have fun with their lessons and building their confidence in the water!",
    hobbies: "",
    email: "support@utahswimacademy.com",
    phone: "559.392.8800",
    isActive: true
  },
  {
    img: hannah,
    name: "Hannah",
    title: "Instructor",
    primary: "2",
    secondary: "1",
    started: "01 February 2024",
    work: "Babies, toddlers, and students working on stroke work",
    language: "English",
    experience: "Has taught swim lesson for over 2 years in California.",
    goals: "Hannah loves working with students in helping them gain confidence when trying new things.",
    hobbies: "",
    email: "support@utahswimacademy.com",
    phone: "530.350.2002",
    isActive: false
  },
  {
    img: london,
    name: "London",
    title: "Instructor",
    primary: "2",
    secondary: "1",
    started: "01 May 2024",
    work: "Babies, shy children, and advanced stroke work.",
    language: "English",
    experience: "London has been on the swim team for 2 years and has been teaching at Utah Swim Academy since 2024.",
    goals: "London enjoys teaching kids something new and having them comfortable with swim lessons.",
    hobbies: "",
    email: "support@utahswimacademy.com",
    phone: "385.237.9041",
    isActive: true
  },
  {
    img: abbie,
    name: "Abbie",
    title: "Instructor",
    primary: "1",
    secondary: "3",
    started: "01 May 2024",
    work: "Toddlers, and students working on stroke work",
    language: "English",
    experience: "Abbie excels in adjusting to different skill levels and personalities.",
    goals: "She does stroke work and works well with disabilities.",
    hobbies: "",
    email: "support@utahswimacademy.com",
    phone: "385.237.9041",
    isActive: false
  },
  {
    img: lindsey,
    name: "Lindsey",
    title: "Instructor",
    primary: "4",
    secondary: "3",
    started: "01 May 2024",
    work: "Babies, Toddlers, Advance Stroke for Swim Team",
    language: "English",
    experience: "Lindsey has been on the swim team for 10 years and has been teaching and a coach for 3 years.",
    goals: "Lindsey finds a lot of joy and fulfillment when her kiddos finally overcome their obstacles and find excitement in their new skills!",
    hobbies: "",
    email: "support@utahswimacademy.com",
    phone: "385.204.3266",
    isActive: false
  },
  {
    img: sarah,
    name: "Sarah",
    title: "Instructor",
    primary: "2",
    secondary: "0",
    started: "01 August 2024",
    work: "All ages and advanced strokes",
    language: "English, Lithuanian",
    experience: "Sarah has been teaching swim lessons for over 7 years and was a competitive swimmer for 11 years here in Utah.",
    goals: "Sarah loves helping kids be more comfortable in the water and also help them enjoy swimming.",
    hobbies: "",
    email: "support@utahswimacademy.com",
    phone: "801.822.4762",
    isActive: false
  },
  {
    img: avery,
    name: "Avery",
    title: "Instructor",
    primary: "1",
    secondary: "0",
    started: "01 August 2024",
    work: "All ages and kids that need that boost of enjoyment",
    language: "English, French",
    experience: "",
    goals: "Avery loves being able to see kids learn, enjoy the water, and build connections with them is what she loves about teaching.",
    hobbies: "",
    email: "support@utahswimacademy.com",
    phone: "406.850.8663",
    isActive: false
  },
  {
    img: bre,
    name: "Bre",
    title: "Instructor",
    primary: "2",
    secondary: "3",
    started: "01 January 2025",
    work: "Toddlers, older children, and advanced stroke work",
    language: "English",
    experience: "Bre joined a swim team whens he was 5 years old and later joined a club team when she turned 11 years old. She is USA-certified swim coach.",
    goals: "Bre's main goal is to help children become more comfortable and confident in the water! She wants to create a safe and fun environment where kids enjoy being in the water.",
    hobbies: "",
    email: "support@utahswimacademy.com",
    phone: "",
    isActive: true
  },
  {
    img: tasha,
    name: "Tasha",
    title: "Instructor",
    primary: "1",
    secondary: "3",
    started: "01 January 2025",
    work: "All ages, abilities and advanced stroke work",
    language: "English",
    experience: "Growing up, Tasha was on a swim team and after became a lifeguard.",
    goals: "Tasha's goal is to create a safe swimming experience where the students can progress with their individual goals. She has always loved the water adn wants others to love it too!",
    hobbies: "",
    email: "support@utahswimacademy.com",
    phone: "",
    isActive: true
  },
  {
    img: izzy,
    name: "Izzy",
    title: "Instructor",
    primary: "1",
    secondary: "3",
    started: "01 March 2025",
    work: "Older children",
    language: "English",
    experience: "Izzy has five years of experience teaching private swim lessons.",
    goals: "Izzy's goal is to create a positive, fun environment where students of all ages and abilities feel comfortable learning at their own pace.",
    hobbies: "",
    email: "support@utahswimacademy.com",
    phone: "",
    isActive: true
  },

  {
    img: katie,
    name: "Katie",
    title: "Instructor",
    primary: "2",
    secondary: "3",
    started: "01 March 2025",
    work: "All ages",
    language: "English, ASL",
    experience: "Katie has club and high school swim team experience and taught swim lessons for one year previously.",
    goals: "Katie's goal is to help children be safe and comfortable in the water. She loves to see the children when the 'light comes on' and they get it [a new skill].",
    hobbies: "",
    email: "support@utahswimacademy.com",
    phone: "",
    isActive: true
  },
  {
    img: jade,
    name: "Jade",
    title: "Instructor",
    primary: "3",
    secondary: "2",
    started: "01 March 2025",
    work: "All ages, but especially small children",
    language: "English",
    experience: "Jade has nine years of swim team experience, taught swim lessons for four years, and coached swim teams for two years.",
    goals: "Jade's goal is to help children love the water as much as she does! She loves that she can give kids the chance to enjoy and be safe in and around the water.",
    hobbies: "",
    email: "support@utahswimacademy.com",
    phone: "",
    isActive: true
  },
  {
    img: shelby,
    name: "Shelby",
    title: "Instructor",
    primary: "3",
    secondary: "2",
    started: "01 March 2025",
    work: "All ages, and advanced stroke work.",
    language: "English",
    experience: "Shelby was a lifeguard for two years and a lifeguard captain for one year.",
    goals: "Shelby's goal is to create and foster an environment where kids learn how to not be afraid of the water, focusing on being comfortable and having fun.",
    hobbies: "",
    email: "support@utahswimacademy.com",
    phone: "",
    isActive: true
  },
  {
    img: bailey,
    name: "Bailey",
    title: "Instructor",
    primary: "2",
    secondary: "3",
    started: "01 March 2025",
    work: "All ages",
    language: "English",
    experience: "Bailey was a lifeguard for a year and taught swim lessons for three years at another facility.",
    goals: "Bailey's goal is to help kids do their best, get a little better with every lesson, and always continue improving. She loves kids and seeing their growth!",
    hobbies: "",
    email: "support@utahswimacademy.com",
    phone: "",
    isActive: true
  },
  {
    img: tallie,
    name: "Tallie",
    title: "Instructor",
    primary: "2",
    secondary: "1",
    started: "01 March 2025",
    work: "Older children and those in the transition phase.",
    language: "English, Spanish",
    experience: "Tallie has swim team experience, played water polo for two years, and was a lifeguard for three years.",
    goals: "Tallie's goal is to help children grow their confidence, love the water, and become independent swimmers.",
    hobbies: "",
    email: "support@utahswimacademy.com",
    phone: "",
    isActive: true
  },
  {
    img: annElise,
    name: "AnnElise",
    title: "Instructor",
    primary: "1",
    secondary: "2",
    started: "01 March 2025",
    work: "Toddlers and older children",
    language: "English",
    experience: "AnnElise was a lifeguard for four years, swim instructor for four years, and on a high school swim team for one year.",
    goals: "AnnElise's goal is to encourage confidence in water safety skills and inspire a love for swimming.",
    hobbies: "",
    email: "support@utahswimacademy.com",
    phone: "",
    isActive: true
  },
];
